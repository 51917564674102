/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
          $('iframe').load( function() {
              $('iframe').contents().find("head").append($("<style type='text/css'>  body{background-color:#fff;}  </style>"));
          });

        $('#testimonialCarousel').carousel({ interval: 4000 });
        $('#heroCarousel').carousel({ interval: 10000 });
        var removeClass = true;
        $(".hamburger").click(function () {
            $(".hamburger").toggleClass('is-active');
            $("#sideNav").toggleClass('sideNav-open');
            $(".sideNavBody").toggleClass('sideNavBody-push');
            removeClass = false;
        });

        $(".sideNav").click(function() {
            removeClass = false;
        });

        $("html").click(function () {
            if (removeClass) {
                $(".hamburger").removeClass('is-active');
                $("#sideNav").removeClass('sideNav-open');
                $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
        });

        $('.btn.btn-primary').click(function(){
            $('html, body').animate({
                scrollTop: $( $(this).attr('href') ).offset().top
            }, 600);
              return false;
        });

        function parseURLParams(url) {
            var queryStart = url.indexOf("?") + 1,
                queryEnd   = url.indexOf("#") + 1 || url.length + 1,
                query = url.slice(queryStart, queryEnd - 1),
                pairs = query.replace(/\+/g, " ").split("&"),
                parms = {}, i, n, v, nv;

            if (query === url || query === "") {
                return;
            }

            for (i = 0; i < pairs.length; i++) {
                nv = pairs[i].split("=");
                n = decodeURIComponent(nv[0]);
                v = decodeURIComponent(nv[1]);

                if (!parms.hasOwnProperty(n)) {
                    parms[n] = [];
                }

                parms[n].push(nv.length === 2 ? v : null);
            }
            return parms;
        }

        var window_url = window.location.href;

        if (window.location.search) {
          var url_results = parseURLParams(window_url);
          var search = url_results.search;
          $('#searchFrame').load(function(){
            var iframeContents = $('#searchFrame').contents().find('#ctl00_secNav_txtSearch');
            var iframeForm = $('#searchFrame').contents().find('#aspnetForm');
            iframeContents.val(search);
            iframeForm.submit();
          });
        }

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $('#brandCarousel').carousel({ interval: false });
        $('.carousel-showmanymoveone .item').each(function(){
            var itemToClone = $(this);

            for (var i=1;i<4;i++) {
              itemToClone = itemToClone.next();

              // wrap around if at end of item collection
              if (!itemToClone.length) {
                itemToClone = $(this).siblings(':first');
              }

              // grab item, clone, add marker class, add to collection
              itemToClone.children(':first-child').clone()
                  .addClass("cloneditem-"+(i))
                  .appendTo($(this));
            }
          });

          $(document).ready(function () {
              $('.scrolldown').fadeIn();
              var ids = $('section').map(function(index) {
                return this.id;
              });

              var position = 1;
              var section;
              var prev = ids[position - 1];
              var next = ids[position];

              function positionPlus() {
                position += 1;
                next = ids[position];
                prev = ids[position - 2];
              }

              function positionMinus() {
                if (position > 1) {
                  position -= 1;
                  next = ids[position];
                  prev = ids[position - 1];
                }
              }

              function currentLocation() {
                for (i = 0; i < ids.length; i++) {
                  if ($('#' + next).offset().top > $(document).height() - $(window).height() && $(window).scrollTop() > $('#' + prev).offset().top) {
                    next = ids[position];
                    prev = ids[position - 1];
                  } else if($(window).scrollTop() >= $('#' + next).offset().top) {
                    positionPlus();
                  } else if ($(window).scrollTop() <= $('#' + prev).offset().top) {
                    positionMinus();
                  }
                }
              }

              $(window).scroll(function () {
                  if ($(this).scrollTop() > $('#header').offset().top) {
                      $('.scrollup').fadeIn();
                  } else {
                      $('.scrollup').fadeOut();
                  }
                  if ($(this).scrollTop() === $(document).height() - $(window).height()) {
                      $('.scrolldown').fadeOut();
                  } else {
                     $('.scrolldown').fadeIn();
                  }
                  currentLocation();
              });

              $('.scrollup').click(function () {
                $("html, body").animate({
                    scrollTop: $('#' + prev).offset().top
                }, 600);
                currentLocation();
                return false;
              });

              $('.scrolldown').click(function () {
                $("html, body").animate({
                    scrollTop: $('#' + next).offset().top
                }, 600);
                currentLocation();
                return false;
              });
          });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.


jQuery(function($){
  var lastHeight = 0, curHeight = 0, $frame = $('iframe:eq(0)');
  setInterval(function(){
    curHeight = $frame.contents().find('body').height();
    if ( curHeight !== lastHeight ) {
      $frame.css('height', (lastHeight = curHeight) + 'px' );
    }
  },500);
});
